<template>
  <div class="h-full w-full print:hidden relative" style="">
    <canvas ref="gaugeCanvas"></canvas>
  </div>
</template>

<script>
import { Chart, Tooltip, CategoryScale, LinearScale, Title, PieController, ArcElement, Legend } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'

let Paired12 = ['#a6cee3', '#1f78b4', '#b2df8a', '#33a02c', '#fb9a99', '#e31a1c', '#fdbf6f', '#ff7f00', '#cab2d6', '#6a3d9a', '#ffff99', '#b15928']

function getTextAngle (context) {
  let segments = context.dataset.data.length
  let segmentDegrees = (180 / (segments + 1) )
  let angle = (segmentDegrees * context.dataIndex + 1) - 60
  return angle
}

export default {
  props: ['data', 'title'],
  mounted () {
    // Custom Coloring
    this.data.datasets = this.data.datasets.map((elem, idx) => {
      elem.backgroundColor = [...Paired12]
      Paired12.splice(0, 1)
      return elem
    })

    Chart.register(Tooltip, CategoryScale, LinearScale, Title, PieController, ArcElement, Legend)

    new Chart(this.$refs.gaugeCanvas, {
      type: 'doughnut',
      data: this.data,
      plugins: [ChartDataLabels],
      options: {
        responsive: true,
        aspectRatio: 1.75,
        maintainAspectRatio: true,
        circumference: 180,
        rotation: -90,
        cutout: '40%',
        plugins: {
          tooltip: {
            enabled: false,
            // position: 'nearest',
            // mode: 'dataset',
            // callbacks: {
            //   label: function (context) {
            //     //   console.log('asldkajsldkasjd', context)
            //     const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
            //     return formatter.format(context.parsed)
            //   },
            //   title: function (context, l) {
            //     console.log('context', context, l)
            //     return context[0].dataset.title
            //   }
            // },
          },

          datalabels: {
            labels: {
              name: {
                display (context) {
                  return context.dataset.showLabels || context.dataset.showLabels === null || context.dataset.showLabels === undefined
                },
                // formatter (value, context) {
                //   console.log('asldkajsldkasjd', context)
                //   return context.chart.data.labels[context.dataIndex]
                // },
                align: 'top',
                // // offset: 15,
                // rotation: getTextAngle, 
                // color: 'rgba(0, 0, 0, 1.0)',
                // backgroundColor: null,
                // font: {
                //   size: 24,
                //   weight: 'bold'
                // },
              },
              value: {
                color: 'black',
                formatter (value, context) {
                  const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                  const total = context.dataset.data.reduce((acc, v) => { acc += parseInt(v); return acc }, 0)
                  return `${context.dataset.labels[context.dataIndex]}\n${formatter.format(value)} (${parseInt((value / total) * 1000) / 10}%)`
                },
                align: 'center',
                font: { size: 12 },
              },
            },
          },
        },
      },
      ...this.options,
    })
  },
}
</script>
