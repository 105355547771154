<template>
  <div class="rating">
    <ul class="list">
      <li @click="rate(star)" v-for="star in maxStars" :class="{ 'active': star <= stars }" :key="star.stars" class="star">
        <svg v-if="star <= stars" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="star" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="w-12"><path fill="currentColor" d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"></path></svg>
        <svg v-else aria-hidden="true" focusable="false" data-prefix="fal" data-icon="star" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="w-12"><path fill="currentColor" d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM405.8 317.9l27.8 162L288 403.5 142.5 480l27.8-162L52.5 203.1l162.7-23.6L288 32l72.8 147.5 162.7 23.6-117.7 114.8z"></path></svg>
      </li>
    </ul>
    <div v-if="hasCounter" class="info counter">
      <span class="score-rating">{{ stars }}</span>
      <span class="divider">/</span>
      <span class="score-max">{{ maxStars }}</span>
    </div>
    <input type="number" v-model="stars" :name="inputName" class="hidden rating__hidden-element" />
  </div>
</template>

<script>

export default {
  name: 'rating',
  props: ['rating', 'maxStars', 'hasCounter', 'name'],
  data () {
    return {
      stars: this.rating || 0,
    }
  },
  computed: {
    inputName () {
      return this.name || 'rating'
    },
  },
  methods: {
    rate (star) {
      if (typeof star === 'number' && star <= this.maxStars && star >= 0) {
        this.stars = this.stars === star ? star - 1 : star
      }
    },
  },
}
</script>

<style scoped lang="scss">
.rating {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  color: #b7b7b7;
  background: #fff;
  border-radius: 8px;
  box-shadow: 2px 1px 10px rgba(19, 18, 18, 0.09);
  .list {
      padding: 0;
      &:hover {
          .star {
              color: #ffe100;
          }
      }
      .star {
          display: inline-block;
          font-size: 42px;
          transition: all .2s ease-in-out; 
          cursor: pointer;
          &:hover {
              ~ .star:not(.active) {
                  color: inherit;
              }
          }
          &:first-child {
              margin-left: 0;
          }
          &.active {
              color: #ffe100;
          }
      }
  }
  .info {
      margin-top: 15px;
      font-size: 40px;
      text-align: center;
      display: table;
      .divider {
          margin: 0 5px;
          font-size: 30px;
      }
      .score-max {
          font-size: 30px;
          vertical-align: sub;
      }
  }
}
</style>