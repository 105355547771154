<template>
  <div class="standard-page-content-container">
    <h1 class="text-2xl mb-4">MRA Orlando Summary Analytics</h1>

    <hr class="mb-4">

    <div class="search-results">
      <div class="flex justify-center mb-4">
        <button class="btn btn-secondary" @click="previousDay">Prior Day</button>
        <div class="font-semibold px-4 text-2xl">{{ currentDate.toFormat('DD (ccc)')}}</div>
        <button class="btn btn-secondary" @click="nextDay">Next Day</button>
      </div>

      <div class="current-view flex justify-center mb-4">
        <nav class="flex space-x-4" aria-label="View">
          <a href="javascript:void(0)" @click="currentView = 'summary'" :class="currentView === 'summary' ? 'bg-blue-100 text-blue-700' : 'text-gray-500 hover:text-gray-700'" class="px-3 py-2 font-medium text-sm rounded-md">
            Overall Summary
          </a>

          <a href="javascript:void(0)" @click="currentView = 'tech_summary'" :class="currentView === 'tech_summary' ? 'bg-blue-100 text-blue-700' : 'text-gray-500 hover:text-gray-700'" class="px-3 py-2 font-medium text-sm rounded-md">
            Tech Summary
          </a>
        </nav>
      </div>

      <hr class="mt-2 mb-2">

      <div class="overall-summary" :class="currentView === 'summary' ? '' : 'hidden'">
        <div class="flex flex-wrap">
          <div class="w-full lg:w-1/2 p-4">
            <h3 class="text-lg font-semibold pb-2">Work Orders</h3>
            <table class="table table-less-column-padding-sticky">
              <tbody>
                <tr>
                  <td># WOs</td>
                  <td>{{ daySummaryAttributes.num_work_orders }}</td>
                </tr>
                <tr>
                  <td># WOs Canc.</td>
                  <td>{{ daySummaryAttributes.num_cancelled_work_orders }}</td>
                </tr>
                <tr>
                  <td># Active WOs</td>
                  <td>{{ daySummaryAttributes.num_active_work_orders }}</td>
                </tr>
                <tr>
                  <td># Diag</td>
                  <td>{{ daySummaryAttributes.total_num_diagnostics }}</td>
                </tr>
                <tr>
                  <td># Callbacks</td>
                  <td>{{ daySummaryAttributes.num_callbacks }}</td>
                </tr>
                <tr>
                  <td># Techs Active</td>
                  <td>{{ daySummaryAttributes.num_active_technicians }}</td>
                </tr>
              </tbody>
            </table>

            <h3 class="text-lg font-semibold pt-4 pb-2">Operational Excellence</h3>
            <table class="table table-less-column-padding-sticky">
              <tbody>
                <tr>
                  <td>Same Day AH</td>
                  <td>{{ daySummaryAttributes.num_same_day_ah }}</td>
                </tr>
                <tr>
                  <td>Same Day Non-AH</td>
                  <td>{{ daySummaryAttributes.num_same_day }}</td>
                </tr>
                <tr>
                  <td>FCCP (Van)</td>
                  <td>{{ daySummaryAttributes.num_fcc_with_part_van_stock_only }}</td>
                </tr>
                <tr>
                  <td>FCCP (Pickup)</td>
                  <td>{{ daySummaryAttributes.num_fcc_with_part_pickup_only }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="w-full lg:w-1/2 p-4">
            <h3 class="text-lg font-semibold pb-2">$$$</h3>
            <table class="table table-less-column-padding-sticky">
              <tbody>
                <tr>
                  <td>$ WO Closed</td>
                  <td>{{ daySummaryAttributes.closed_work_order_subtotal | currency }}</td>
                </tr>
                <tr>
                  <td>$ WO Sold</td>
                  <td>{{ daySummaryAttributes.work_order_new_sale_subtotal | currency }}</td>
                </tr>
                <tr>
                  <td>$ Payments</td>
                  <td>{{ daySummaryAttributes.sum_payments | currency }}</td>
                </tr>
                <tr>
                  <td># Payments</td>
                  <td>{{ daySummaryAttributes.num_payments }}</td>
                </tr>
                <tr>
                  <td>$ Refunds</td>
                  <td>{{ daySummaryAttributes.sum_refunds | currency }}</td>
                </tr>
              </tbody>
            </table>

            <h3 class="text-lg font-semibold pt-4 pb-2">Marketing, Phones, etc.</h3>
            <table class="table table-less-column-padding-sticky">
              <tbody>
                <tr>
                  <td># LSA Leads</td>
                  <td>{{ daySummaryAttributes.num_lsa_leads }}</td>
                </tr>
                <tr>
                  <td># Uniq. Inb. Callers</td>
                  <td>{{ daySummaryAttributes.num_unique_inbound_callers }}</td>
                </tr>
                </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="technician-summary" :class="currentView === 'tech_summary' ? '' : 'hidden'">
        <vue-good-table
          :columns="dayTechSummaryColumns"
          :rows="dayTechSummaryAttributes"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { WindowVariables } from '@utils'
import { find } from 'lodash-es'

export default {
  name: 'reports-app',
  data () {
    var that = this
    return {
      currentDate: DateTime.local().startOf('day'),
      currentView: 'summary',
      byDateSummaryData: WindowVariables.daySummaryResults,
      byDateAndTechnicianData: WindowVariables.dayAndTechnicianSummaryResults,
      daySummaryColumns: [

      ],
      dayTechSummaryColumns: [
        {
          label: 'Name',
          field: 'technician_name',
        },
        {
          label: '# Active WOs',
          field: 'num_active_work_orders',
          type: 'number',
        },
        {
          label: '# Diag',
          field: 'total_num_diagnostics',
          type: 'number',
        },
        {
          label: '# Callbacks',
          field: 'num_callbacks',
          type: 'number',
        },
        {
          label: '# Added Same Day',
          field: 'num_same_day',
          type: 'number',
        },
        {
          label: '$ Payments',
          field: 'sum_payments',
          formatFn (value) {
            return that.$options.filters.currency(value)
          },
        },
        {
          label: 'Closed $',
          field: 'closed_work_order_subtotal',
          formatFn (value) {
            return that.$options.filters.currency(value)
          },
        },
        {
          label: 'FCCP Pickup',
          field: 'num_fcc_with_part_pickup_only',
        },
        {
          label: 'FCCP Van',
          field: 'num_fcc_with_part_van_stock_only',
        },
      ],
    }
  },
  methods: {
    nextDay () {
      this.currentDate = this.currentDate.plus({ days: 1})
    },
    previousDay () {
      this.currentDate = this.currentDate.minus({ days: 1 })
    }
  },
  computed: {
    daySummaryAttributes () {
      console.log(WindowVariables.daySummaryResults, this.byDateSummaryData)
      const data = find(this.byDateSummaryData, (d) => {
        console.log(DateTime.fromISO(d.gregorian).startOf('day').toISO(), this.currentDate.toISO(), DateTime.fromISO(d.gregorian).startOf('day').toMillis() === this.currentDate.toMillis())
        return DateTime.fromISO(d.gregorian).startOf('day').toMillis() === this.currentDate.toMillis()
      })
      console.log('found date match', data)
      return data
    },
    dayTechSummaryAttributes () {
      return this.byDateAndTechnicianData.filter((d) => {
        return DateTime.fromISO(d.gregorian).startOf('day').toMillis() === this.currentDate.toMillis()
      })
    },
  },
  created () {
  }

}
</script>