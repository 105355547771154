import axios from 'axios'
import OfficeAlert from '@models/OfficeAlert'
import { WindowVariables } from '@utils'

const BASE_URL = `${WindowVariables.primaryDomain}/office_alerts`

function init (d) {
  return new OfficeAlert(d)
}

export default {
  async getAll () {
    return await axios.get(`${BASE_URL}.json`)
      .then(resp => resp.data.map(init))
  },
  async create (params) {
    return await axios.post(`${BASE_URL}.json`, { ...params })
      .then(resp => init(resp.data))
  },
  async archive (id) {
    return await axios.patch(`${BASE_URL}/${id}/archive.json`)
      .then(resp => init(resp.data))
  },
}