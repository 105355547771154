// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
// require("channels")

// import tippy from 'tippy.js'
// import 'tippy.js/dist/tippy.css'

// require('alpinejs')
require('../stylesheets/application.scss')

import flatpickr from 'flatpickr'
import 'flatpickr/dist/flatpickr.min.css'
import Chart from 'chart.js'
// require('chartjs-plugin-colorschemes')

import GLightbox from 'glightbox'
import 'glightbox/dist/css/glightbox.min.css'

import Vue from 'vue'
Vue.config.devtools = true
import * as VueGoogleMaps from 'gmap-vue'

import { DateTime } from 'luxon'
window.DateTime = DateTime

import * as mobx from 'mobx'
import Movue from 'movue'
Vue.use(Movue, mobx)

import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'

import VueCurrencyFilter from 'vue-currency-filter'
Vue.use(VueCurrencyFilter, {
  symbol : '$',
  thousandsSeparator: ',',
  fractionCount: 2,
  fractionSeparator: '.',
  symbolPosition: 'front',
  symbolSpacing: true,
  avoidEmptyDecimals: undefined,
})

import VueGoodTablePlugin from 'vue-good-table'

// import the styles
import 'vue-good-table/dist/vue-good-table.css'

Vue.use(VueGoodTablePlugin)

import MapApp from '../map_app.vue'
import PartsSearchApp from '../parts_search_app.vue'
import ReportsApp from '../reports_app.vue'
import Rating from '@components/Rating.vue'
import OpenModalButton from '@components/OpenModalButton.vue'
import TechnicianStatusReport from '@components/TechnicianStatusReport.vue'
import TechnicianAlertList from '@components/TechnicianAlertList.vue'
import OfficeAlert from '@components/OfficeAlert.vue'
import ManageTechnicianAlerts from '@components/routes/ManageTechnicianAlerts.vue'
import ReturnBoxPartSelection from '@components/ReturnBoxPartSelection.vue'
import BarcodeManager from '@components/BarcodeManager.vue'
import GaugeChart from '@components/charts/GaugeChart.vue'

Vue.filter('toCurrency', (value) => {
  if (typeof value !== "number") {
    value = parseFloat(value)
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
  })
  return formatter.format(value)
})

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAIxvHuaizGHcJ6D23lpFEIWrkMj9R2p48',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then set installComponents to 'false'.
  //// If you want to automatically install all the components this property must be set to 'true':
  installComponents: true
})

document.addEventListener('turbolinks:load', () => {
  flatpickr('.datepicker')

  // tippy('[data-tippy-content]')

  const lightBox = GLightbox({
    beforeSlideLoad: (slide, data) => {
      console.log('beforeSlideLoad', slide, data)
      return
    }
  })

  new Vue({
    el: document.querySelector('#app'),
    render: h => h(MapApp)
  })

  if (document.querySelector('#parts-search-app')) {
    new Vue({
      el: document.querySelector('#parts-search-app'),
      render: h => h(PartsSearchApp)
    })
  }

  if (document.querySelector('#reports-app')) {
    new Vue({
      el: document.querySelector('#reports-app'),
      render: h => h(ReportsApp)
    })
  }

  if (document.querySelector('.technician-dashboard #technician-alerts-app')) {
    new Vue({
      el: document.querySelector('.technician-dashboard #technician-alerts-app'),
      render: h => h(ManageTechnicianAlerts, {
        props: { embedded: true },
      }),
    })
  }

  const COMPONENT_MAP = {
    Rating,
    OpenModalButton,
    TechnicianStatusReport,
    TechnicianAlertList,
    OfficeAlert,
    ReturnBoxPartSelection,
    VueJsonPretty,
    BarcodeManager,
    GaugeChart,
  }

  const toInstantiate = document.querySelectorAll('[data-vue-component]')
  if (toInstantiate.length > 0) {
    toInstantiate.forEach(el => {
      new Vue({
        el,
        render: h => h(COMPONENT_MAP[el.attributes['data-vue-component'].value], {
          props: JSON.parse(el.attributes['data-props'].value),
          class: [...el.classList.values()],
        }),
      })
    })
  }

  // if (window.location.href.includes('/users/')) {
  //   const e = document.querySelector('#permissions-app')
  //   if (e) {
  //     new Vue({
  //       el: e,
  //       render: h => h(PermissionsApp)
  //     })
  //   }
  // }
})




// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
