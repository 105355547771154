<template>
  <div class="standard-page-content-container">
    <h1 class="text-2xl mb-4">MRA Orlando Parts Search</h1>

    <div class="search-bar flex">
      <input class="one-off-standard-form-input mr-2" placeholder="Enter a Part Number" v-model="partNumberSearch" type="text"/>
      <button class="btn btn-primary" @click="runPartsSearch()">Search!</button>
    </div>

    <hr class="mt-4 mb-4">


    <div class="search-results">
      <h3 class="text-xl mb-2 pt-4 pb-4">Results Summary</h3>
      <table class="table table-less-column-padding-sticky">
        <thead>
          <tr>
            <th>Vendor</th>
            <th>Status</th>
            <th>Our Price</th>
            <th>MSRP</th>
            <th>Pickup Qty</th>
            <th>Total Qty</th>
            <th>Manu Avail?</th>
            <th>Closest Avail?</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <a v-if="marconeResults.pageUrl" target="_blank" :href="marconeResults.pageUrl">Marcone</a>
              <template v-else>Marcone</template>
              <span class="inline-block align-bottom cursor-pointer" :class="{ 'hidden': !(marconeResults.status === 'Error' || marconeResults.status === 'Ran') }" @click="(marconeResults.status === 'Error' || marconeResults.status === 'Ran') && runMarconeQuery()"><svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"></path></svg></span>
            </td>
            <td>{{ marconeResults.status}}{{ marconeResults.secondsElapsed ? ` - ${marconeResults.secondsElapsed}s`: '' }}</td>
            <td class="text-right" :class="{ 'best-price': bestPrice === marconeResults.itemPrice }">{{ marconeResults.itemPrice | toCurrency }}</td>
            <td class="text-right">{{ marconeResults.msrpPrice | toCurrency }}</td>
            <td class="text-right" :class="{ 'has-pickup': marconeResults.pickupQuantity > 0 }">{{ marconeResults.pickupQuantity }}</td>
            <td class="text-right" :class="{ 'has-quantity': marconeResults.totalQuantityAvailable }">{{ marconeResults.totalQuantityAvailable }}</td>
            <td class="text-right">{{ marconeResults.availableAtVendor && marconeResults.availableAtVendor.quantity }}</td>
            <td v-if="marconeResults.closestShippedStock">{{ marconeResults.closestShippedStock.warehouse }} | {{ marconeResults.closestShippedStock.quantity }}</td>
            <td v-else></td>
          </tr>
          <tr>
            <td>
              <a v-if="reliableResults.pageUrl" target="_blank" :href="reliableResults.pageUrl">Reliable</a>
              <template v-else>Reliable</template>
              <span class="inline-block align-bottom cursor-pointer" :class="{ 'hidden': !(reliableResults.status === 'Error' || reliableResults.status === 'Ran') }" @click="(reliableResults.status === 'Error' || reliableResults.status === 'Ran') && runReliableQuery()"><svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"></path></svg></span>
            </td>
            <td>{{ reliableResults.status}}{{ reliableResults.secondsElapsed ? ` - ${reliableResults.secondsElapsed}s`: '' }}</td>
            <td class="text-right" :class="{ 'best-price': bestPrice === reliableResults.itemPrice }">{{ reliableResults.itemPrice | toCurrency }}</td>
            <td class="text-right">{{ reliableResults.msrpPrice | toCurrency }}</td>
            <td class="text-right" :class="{ 'has-pickup': reliableResults.pickupQuantity > 0 }">{{ reliableResults.pickupQuantity }}</td>
            <td class="text-right" :class="{ 'has-quantity': reliableResults.totalQuantityAvailable }">{{ reliableResults.totalQuantityAvailable }}</td>
            <td class="text-right">{{ reliableResults.availableAtVendor && reliableResults.availableAtVendor.quantity }}</td>
            <td v-if="reliableResults.closestShippedStock">{{ reliableResults.closestShippedStock.warehouse }} | {{ reliableResults.closestShippedStock.quantity }}</td>
            <td v-else></td>
          </tr>
          <tr>
            <td>
              <a v-if="foxResults.pageUrl" target="_blank" :href="foxResults.pageUrl">Fox</a>
              <template v-else>Fox</template>
              <span class="inline-block align-bottom cursor-pointer" :class="{ 'hidden': !(foxResults.status === 'Error' || foxResults.status === 'Ran') }" @click="(foxResults.status === 'Error' || foxResults.status === 'Ran') && runFoxQuery()"><svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"></path></svg></span>
            </td>
            <td>{{ foxResults.status }}{{ foxResults.secondsElapsed ? ` - ${foxResults.secondsElapsed}s`: '' }}</td>
            <td class="text-right" :class="{ 'best-price': bestPrice === foxResults.itemPrice }">{{ foxResults.itemPrice | toCurrency }}</td>
            <td class="text-right">{{ foxResults.msrpPrice | toCurrency }}</td>
            <td class="text-right" :class="{ 'has-pickup': foxResults.pickupQuantity > 0 }">{{ foxResults.pickupQuantity }}</td>
            <td class="text-right" :class="{ 'has-quantity': foxResults.totalQuantityAvailable }">{{ foxResults.totalQuantityAvailable }}</td>
            <td class="text-right">{{ foxResults.availableAtVendor && foxResults.availableAtVendor.quantity }}</td>
            <td v-if="foxResults.closestShippedStock">{{ foxResults.closestShippedStock.warehouse }} | {{ foxResults.closestShippedStock.quantity }}</td>
            <td v-else></td>
          </tr>
          <tr>
            <td>
              <a v-if="deyResults.pageUrl" target="_blank" :href="deyResults.pageUrl">Dey</a>
              <template v-else>Dey</template>
              <span class="inline-block align-bottom cursor-pointer" :class="{ 'hidden': !(deyResults.status === 'Error' || deyResults.status === 'Ran') }" @click="(deyResults.status === 'Error' || deyResults.status === 'Ran') && runDeyQuery()"><svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"></path></svg></span>
            </td>
            <td>{{ deyResults.status}}{{ deyResults.secondsElapsed ? ` - ${deyResults.secondsElapsed}s`: '' }}</td>
            <td class="text-right" :class="{ 'best-price': bestPrice === deyResults.itemPrice }">{{ deyResults.itemPrice | toCurrency }}</td>
            <td class="text-right">{{ deyResults.msrpPrice | toCurrency }}</td>
            <td class="text-right" :class="{ 'has-pickup': deyResults.pickupQuantity > 0 }">{{ deyResults.pickupQuantity }}</td>
            <td class="text-right" :class="{ 'has-quantity': deyResults.totalQuantityAvailable }">{{ deyResults.totalQuantityAvailable }}</td>
            <td class="text-right">{{ deyResults.availableAtVendor && deyResults.availableAtVendor.quantity }}</td>
            <td v-if="deyResults.closestShippedStock">{{ deyResults.closestShippedStock.warehouse }} | {{ deyResults.closestShippedStock.quantity }}</td>
            <td v-else></td>
          </tr>
        </tbody>
      </table>


      <div class="inventories flex">
        <div class="reliable-parts-results mx-2" style="min-width: 320px;">
          <h4 class="text-xl font-medium pt-4 pb-4">Reliable Warehouse Availability</h4>
          <table class="table table-less-column-padding-sticky max-w-xs">
            <thead>
              <tr>
                <th>Warehouse</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row in reliableResults.warehouseInventories" :key="row.warehouse">
                <td>{{ row.warehouse }}</td>
                <td>{{ row.quantity }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="dey-parts-results mx-2" style="min-width: 320px;">
          <h4 class="text-xl font-medium pt-4 pb-4">Dey Warehouse Availability</h4>
          <table class="table table-less-column-padding-sticky max-w-xs">
            <thead>
              <tr>
                <th>Warehouse</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row in deyResults.warehouseInventories" :key="row.warehouse">
                <td>{{ row.warehouse }}</td>
                <td>{{ row.quantity }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="fox-parts-results mx-2" style="min-width: 320px;">
          <h4 class="text-xl font-medium pt-4 pb-4">Fox Warehouse Availability</h4>
          <table class="table table-less-column-padding-sticky max-w-xs">
            <thead>
              <tr>
                <th>Warehouse</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row in foxResults.warehouseInventories" :key="row.warehouse">
                <td>{{ row.warehouse }}</td>
                <td>{{ row.quantity }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

// [{
//   "partNumber": "74004053",
//   "pickupIsAvailable": true,
//   "pickupQuantity": 7,
//   "totalQuantityAvailable": 0,
//   "isOutOfStock": true,
//   "closestShippedStock": {
//     "warehouse": "",
//     "quantity": null
//   },
//   "availableAtVendor": {
//     "quantity": 42
//   },
//   "itemPrice": 10.97,
//   "msrpPrice": 27.78,
//   "crawledAt": "2020-11-28T00:39:19.681Z"
// }]
function queryMarcone(partNumber) {
  return axios.post(`https://api.apify.com/v2/acts/UeDda5Asc2LrZNkQ8/run-sync-get-dataset-items?token=vHYygRiomL4oRKQsYBpRJu6g9`, {
    part_number: partNumber,
  }, {
    headers: {
      'Content-Type': 'application/json',
    }
  })
  .then((response) => {
    console.log('marcone response', response)
    return response.data
  }, (error) => error)
}



function queryReliable(partNumber) {
  return axios.post(`https://api.apify.com/v2/acts/HuWLw71TXuestLID3/run-sync-get-dataset-items?token=vHYygRiomL4oRKQsYBpRJu6g9`, {
    part_number: partNumber,
  }, {
    headers: {
      'Content-Type': 'application/json',
    }
  })
  .then((response) => {
    console.log('reliable response', response)
    return response.data
  }, (error) => error)
}

function queryDey(partNumber) {
  return axios.post(`https://api.apify.com/v2/acts/JZojooIWs2NWCyKt2/run-sync-get-dataset-items?token=vHYygRiomL4oRKQsYBpRJu6g9`, {
    part_number: partNumber,
  }, {
    headers: {
      'Content-Type': 'application/json',
    }
  })
  .then((response) => {
    console.log('dey response', response)
    return response.data
  }, (error) => error)
}

function queryFox(partNumber) {
  return axios.post(`https://api.apify.com/v2/acts/qLQfXrbnzdV2NUVbf/run-sync-get-dataset-items?token=vHYygRiomL4oRKQsYBpRJu6g9`, {
    part_number: partNumber,
  }, {
    headers: {
      'Content-Type': 'application/json',
    }
  })
  .then((response) => {
    console.log('fox response', response)
    return response.data
  }, (error) => error)
}

export default {
  name: 'parts-search-app',
  data () {
    return {
      partNumberSearch: '',
      marconeResults: { status: '' },
      reliableResults: { status: '' },
      foxResults: { status: '' },
      deyResults: { status: '' },
      countInterval: null,
      bestPrice: null,
    }
  },
  methods: {
    calculateBestPrice () {
      const arr = []
      if (this.marconeResults.itemPrice) {
        arr.push(this.marconeResults.itemPrice)
      }

      if (this.reliableResults.itemPrice) {
        arr.push(this.reliableResults.itemPrice)
      }

      if (this.deyResults.itemPrice) {
        arr.push(this.deyResults.itemPrice)
      }

      if (this.foxResults.itemPrice) {
        arr.push(this.foxResults.itemPrice)
      }

      console.log('bestprice array', arr)

      this.bestPrice = Math.min(...arr)
    },
    setupCountingInterval () {
      if (this.countInterval) {
        return false
      }

      this.countInterval = setInterval(() => {
        if (this.reliableResults.status === 'Running') {
          this.reliableResults.secondsElapsed += 1
        }

        if (this.marconeResults.status === 'Running') {
          this.marconeResults.secondsElapsed += 1
        }

        if (this.deyResults.status === 'Running') {
          this.deyResults.secondsElapsed += 1
        }

        if (this.foxResults.status === 'Running') {
          this.foxResults.secondsElapsed += 1
        }

        if (this.deyResults.status !== 'Running' &&
            this.reliableResults.status !== 'Running' &&
            this.marconeResults.status !== 'Running' &&
            this.foxResults.status !== 'Running') {
              console.log('clearing interval')
              clearInterval(this.countInterval)
              this.countInterval = null
            }
      }, 1000)
    },
    runPartsSearch () {
      this.runReliableQuery()
      this.runMarconeQuery()
      this.runDeyQuery()
      this.runFoxQuery()
    },
    runReliableQuery () {
      this.reliableResults = {
        status: 'Running',
        secondsElapsed: 0,
      }
      this.setupCountingInterval()
      queryReliable(this.partNumberSearch)
        .then((data) => {
          this.reliableResults = Object.assign(this.reliableResults, data[0])
          this.reliableResults.status = 'Ran'
          this.calculateBestPrice()
        }, (error) => {
          this.reliableResults.status = 'Error'
        })
    },
    runMarconeQuery () {
      this.marconeResults = {
        status: 'Running',
        secondsElapsed: 0,
      }
      this.setupCountingInterval()
      queryMarcone(this.partNumberSearch)
        .then((data) => {
          this.marconeResults = Object.assign(this.marconeResults, data[0])
          this.marconeResults.status = 'Ran'
          this.calculateBestPrice()
        }, (error) => {
          this.marconeResults.status = 'Error'
        })
    },
    runDeyQuery () {
      this.deyResults = {
        status: 'Running',
        secondsElapsed: 0,
      }
      this.setupCountingInterval()
      queryDey(this.partNumberSearch)
        .then((data) => {
          this.deyResults = Object.assign(this.deyResults, data[0])
          this.deyResults.status = 'Ran'
          this.calculateBestPrice()
        }, (error) => {
          console.log('dey results error')
          this.deyResults.status = 'Error'
        })
    },
    runFoxQuery () {
      this.foxResults = {
        status: 'Running',
        secondsElapsed: 0,
      }
      this.setupCountingInterval()
      queryFox(this.partNumberSearch)
        .then((data) => {
          this.foxResults = Object.assign(this.foxResults, data[0])
          this.foxResults.status = 'Ran'
          this.calculateBestPrice()
        }, (error) => {
          console.log('dey results error')
          this.foxResults.status = 'Error'
        })
    },
  },
  computed: {
  },
  created () {
  }

}
</script>