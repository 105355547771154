<template>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Manufacturer
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Part #
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Vendor
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Expected Return Value (+Core)
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Return Type
                </th>
                <th v-if="showCheckbox" scope="col" class="relative px-6 py-3"></th>
                <th v-if="showReaNumberField" scope="col" class="relative px-6 py-3">
                  Vendor REA Number
                </th>
                <th v-if="showReimbursementField" scope="col" class="relative px-6 py-3">
                  Reimbursement
                </th>
                <th v-if="showReimbursementIcon" scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                @click="unlocked && selectRow(part)"
                v-for="part in enrichedData"
                :key="part.key"
                class="even:bg-gray-50 odd:bg-white"
              >
                <td class="border-none px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {{ part.manufacturer }}
                </td>
                <td class="border-none px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <a class="ml-1 cursor-pointer hover:text-gray-700 text-blue-500" style="height:25px;width:25px;" :href="`/returns/parts/${part.id}/edit`">
                    {{ part.part_number }}
                  </a>
                  <!-- Pencil <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M497.9 142.1l-46.1 46.1c-4.7 4.7-12.3 4.7-17 0l-111-111c-4.7-4.7-4.7-12.3 0-17l46.1-46.1c18.7-18.7 49.1-18.7 67.9 0l60.1 60.1c18.8 18.7 18.8 49.1 0 67.9zM284.2 99.8L21.6 362.4.4 483.9c-2.9 16.4 11.4 30.6 27.8 27.8l121.5-21.3 262.6-262.6c4.7-4.7 4.7-12.3 0-17l-111-111c-4.8-4.7-12.4-4.7-17.1 0zM124.1 339.9c-5.5-5.5-5.5-14.3 0-19.8l154-154c5.5-5.5 14.3-5.5 19.8 0s5.5 14.3 0 19.8l-154 154c-5.5 5.5-14.3 5.5-19.8 0zM88 424h48v36.3l-64.5 11.3-31.1-31.1L51.7 376H88v48z"></path></svg> -->
                </td>
                <td class="border-none px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {{ part.partVendor }}
                </td>
                <td class="border-none px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {{ part.return_value | toCurrency }}
                  <template v-if="part.return_core_value > 0">(with {{ part.return_core_value | toCurrency }} core)</template>
                  <template v-else>(no core)</template>
                </td>
                <td class="border-none px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {{ part.returnType }}
                </td>
                <td v-if="showCheckbox" class="border-none px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <div class="ml-3 flex items-center h-5 justify-end">
                    <input :id="part.key" type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded">
                  </div>
                </td>
                <td v-if="showReaNumberField" class="border-none px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <input :value="part.part_vendor_rea_number" :data-record-id="part.id" placeholder="REA number" type="text" class="rea-input-box max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md" />
                </td>
                <td v-if="showReimbursementField" class="border-none px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <template v-if="allowEditingReimbursementValue">
                    <div class="relative rounded-md shadow-sm w-24">
                      <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <span class="text-gray-500 sm:text-sm">$</span>
                      </div>
                      <input :value="part.part_reimbursement" :data-record-id="part.id" type="text" class="reimbursement-input-box focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-2 sm:text-sm border-gray-300 rounded-md" placeholder="0.00">
                    </div>
                  </template>
                  <template v-else>
                    {{ (part.part_reimbursement || 0.00) | toCurrency }}
                  </template>
                </td>
                <td v-if="showReimbursementIcon" class="border-none px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <div style="height:25px;width:25px;">
                    <!-- Check Mark --><svg v-if="showReimbursementCheck(part)" aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="text-green-500"><path fill="currentColor" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
                    <!-- Warning --><svg v-if="showReimbursementWarning(part)" aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="text-mra-orange-light"><path fill="currentColor" d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"></path></svg>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReturnPart from '@models/ReturnPart'
import ReturnParts from '@http/ReturnParts'
import Notify from '@services/Notify'
import { debounce } from 'lodash-es'

export default {
  name: 'return-box-part-selection',
  props: {
    data: {
      type: Array,
      required: true,
    },
    returnBoxId: {
      type: Number,
      required: true,
    },
    returnBoxStatus: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      selectedPartKeys: [],
      enrichedData: this.data.map(pt => new ReturnPart(pt)),
    }
  },
  mounted () {
    this.selectedPartKeys = this.enrichedData
      .filter(pt => pt.return_box_id === this.returnBoxId)
      .map(pt => pt.key)
    this.updateCheckboxes()

    this.saveReaNumberDebounced = debounce(this.saveReaNumber, 200)
    this.saveReimbursementDebounced = debounce(this.saveReimbursement, 200)

    document.querySelectorAll('.rea-input-box').forEach(el => {
      el.addEventListener('keyup', this.saveReaNumberDebounced)
    })
    document.querySelectorAll('.reimbursement-input-box').forEach(el => {
      el.addEventListener('keyup', this.saveReimbursementDebounced)
    })
  },
  computed: {
    unlocked () {
      return this.returnBoxStatus === 'opened'
    },
    showCheckbox () {
      return this.returnBoxStatus === 'opened'
    },
    showReaNumberField () {
      return this.returnBoxStatus !== 'opened'
    },
    showReimbursementField () {
      return this.returnBoxStatus === 'delivered' || this.returnBoxStatus === 'reimbursed'
    },
    showReimbursementIcon () {
      return this.returnBoxStatus === 'delivered' || this.returnBoxStatus === 'reimbursed'
    },
    allowEditingReimbursementValue () {
      return this.returnBoxStatus !== 'reimbursed'
    },
  },
  methods: {
    selectRow (row) {
      const idx = this.selectedPartKeys.indexOf(row.key)
      const addingPart = idx < 0
      if (addingPart) {
        this.addPart(row)
      } else {
        this.removePart(row)
      }
      return this.saveSelection(row, addingPart)
        .then(this.updateCheckboxes)
        .catch(resp => {
          // Revert
          if (addingPart) {
            this.removePart(row)
          } else {
            this.addPart(row)
          }
          this.updateCheckboxes()
          return resp
        })
    },
    addPart (row) {
      this.selectedPartKeys = [...this.selectedPartKeys, row.key]
    },
    removePart (row) {
      const idx = this.selectedPartKeys.indexOf(row.key)
      this.selectedPartKeys.splice(idx, 1)
    },
    updateCheckboxes () {
      const allCheckboxes = document.querySelectorAll('input[type="checkbox"]')
      allCheckboxes.forEach(chk => {
        chk.checked = !!this.selectedPartKeys.find(partKey => chk.id === partKey)
      })
    },
    saveSelection (row, adding) {
      return adding
        ? ReturnParts.addToBox(row.id, this.returnBoxId)
        : ReturnParts.removeFromBox(row.id)
    },
    saveReaNumber (evt) {
      const el = evt.target
      const id = parseInt(el.attributes['data-record-id'].value)
      const part = this.enrichedData.find(d => d.id === id)
      return ReturnParts.update({ id, part_vendor_rea_number: el.value })
        .then(rp => {
          part.part_vendor_rea_number = el.value
          Notify.success(`REA number updated for ${part.part_number}`)
        })
        .catch(resp => {
          Notify.error(`Failed to update REA number for ${part.part_number}`)
          console.error(`Failed to update REA number for ${part.part_number}`, resp)
          el.value = ''
        })
    },
    saveReimbursement (evt) {
      const el = evt.target
      const id = parseInt(el.attributes['data-record-id'].value)
      const part = this.enrichedData.find(d => d.id === id)
      return ReturnParts.update({ id, part_reimbursement: el.value })
        .then(rp => {
          part.part_reimbursement = el.value
          Notify.success(`Reimbursement amount updated for ${part.part_number}`)
        })
        .catch(resp => {
          Notify.error(`Failed to update reimbursement amount for ${part.part_number}`)
          console.error(`Failed to update reimbursement amount for ${part.part_number}`, resp)
          el.value = ''
        })
    },
    showReimbursementCheck (part) {
      return parseFloat(part.part_reimbursement).toFixed(2) === (parseFloat(part.return_value) + parseFloat(part.return_core_value)).toFixed(2)
    },
    showReimbursementWarning (part) {
      return (
        parseFloat(part.part_reimbursement) > 0 && parseFloat(part.part_reimbursement).toFixed(2) !== (parseFloat(part.return_value) + parseFloat(part.return_core_value)).toFixed(2)
      ) || (
        this.returnBoxStatus === 'reimbursed' && parseFloat(part.part_reimbursement || 0) === 0
      )
    },
  },
  destroyed () {
    document.querySelectorAll('.rea-input-box').forEach(el => {
      el.removeEventListener('keyup', this.saveReaNumberDebounced)
    })
    document.querySelectorAll('.reimbursement-input-box').forEach(el => {
      el.removeEventListener('keyup', this.saveReimbursementDebounced)
    })
  },
}
</script>
