<template>
  <div class="barcode-manager-wrapper p-4 overflow-none border-gray-300 border-2 border-solid mt-4 mb-4 print:border-0" style="max-width: 1000px">
    <div class="section mb-4" v-for="s in theData.sections" :key="s.id" :class="{'print:hidden': !s.show }">
      <div class="section-name pt-4 pb-2 text-xl">
        <input v-model="s.name" class="input p-2"/>
        <button class="print:hidden text-sm ml-4 border-1 border-black bg-green-300 p-2 rounded" @click="s.show = s.show ? false: true">{{s.show ? 'Hide for Print' : 'Show for Print' }}</button>
        <button @click="removeSection(s.id)" class="ml-4 text-sm border-1 border-black bg-red-300 p-2 rounded print:hidden">Remove Section</button>
      </div>
      <div class="grid grid-cols-3 gap-4">
        <div class="grid-cell border bg-white border-black border-solid relative" v-for="barcode in s.barcodes" :key="barcode.id">
          <template v-if="barcode.mode == 'view'">
            <div class="barcode-label text-center" v-if="barcode.label">{{barcode.label}}</div>
            <svg class="barcode-container mx-auto" :id="'barcode-' + barcode.id"></svg>
            <div class="barcode-toggle absolute top-1 right-1 print:hidden cursor-pointer" @click="toggleBarcodeMode(barcode)">
              {{barcode.mode == 'view' ? 'edit' : 'view'}}
            </div>
            <div class="barcode-remove absolute top-6 right-1 print:hidden cursor-pointer" @click="removeBarcode(s.id, barcode.id)">
              &nbsp;x&nbsp;
            </div>
          </template>
          <template v-else>
            <input type="text" v-model="barcode.label" placeholder="Label"/>
            <input type="text" v-model="barcode.codeText" placeholder="Part Number"/>
            <div class="barcode-toggle absolute top-1 right-1 print:hidden cursor-pointer" @click="toggleBarcodeMode(barcode)">
              {{barcode.mode == 'view' ? 'edit' : 'flip'}}
            </div>
          </template>
        </div>

        <div class="grid-cell grid-cell-add bg-gray-100 border-black border-solid border relative print:hidden text-center cursor-pointer p-4 bg-white" @click="addBarcodeToSection(s.id)">
          Add Barcode
        </div>
      </div>
    </div>

    <button @click="addSection()" class="btn btn-secondary print:hidden mb-2">Add Section</button>
    <br>
    <button @click="save()" :disabled="saveState !== null" class="btn btn-primary print:hidden">{{saveButtonText}}</button>
  </div>
</template>

<script>
import { WindowVariables } from '@utils'
import { find, forEach } from 'lodash-es'
import axios from 'axios'

const jsBarcode = require('jsbarcode')

export default {
  name: 'barcode-manager',
  props: {
    data: {
      type: Object,
    },
    id: {
      type: Number,
      required: true,
    }
  },
  data () {
    console.log('data', this.data)
    if (!this.data) {
      var d = {
        sections: []
      }
    }

    return {
      theData: d || this.data,
      saveButtonText: 'Save',
      saveState: null,
    }
  },
  computed: {


  },
  methods: {
    addSection() {
      var newSection = {
        name: 'Default',
        id: Math.round(Math.random() * 100000000),
        show: true,
        barcodes: [
        ]
      }

      for(var i=0; i < 1; i++) {
        newSection.barcodes.push({
          id: Math.round(Math.random() * 10000000),
          codeText: '',
          label: '',
          mode: 'edit',
        })
      }
      this.theData.sections.push(newSection)
    },
    toggleBarcodeMode(barcode) {
      if (barcode.mode === 'view') {
        barcode.mode = 'edit'
      } else {
        barcode.mode = 'view'
        this.regenerateBarcode(barcode.id)
      }
    },
    regenerateBarcode(barcodeId) {
      forEach(this.theData.sections, section => {
        forEach(section.barcodes, barcode => {
          if (barcode.id == barcodeId) {
            console.log('found a match', barcode, barcodeId)
            setTimeout(() => {
              jsBarcode('#barcode-' + barcode.id.toString(), barcode.codeText, {
                format: 'code128',
                height: 36,
                width: 1,
              }, 100)
            })

          }
        })
      })
    },
    save() {
      if (this.saveState == null) {
        this.saveState = 'saving'
        this.saveButtonText = 'saving...'
        axios.put('/barcode_managers/' + this.id + '.json', {
          metadata: this.theData
        })
        .then((resp) => {
          this.saveButtonText = "SAVED! :-)"
          console.log('barcode manager update', resp)
        }, (resp) => {
          this.saveButtonText = 'Save FAILED'
        })
        .finally(() => {
          setTimeout(() => {
            this.saveButtonText = 'Save'
            this.saveState = null
          }, 2000)
        })
      } else {

      }

    },
    removeSection(sectionId) {
      const idx = this.theData.sections.findIndex(s => s.id === sectionId)
      if (idx >= 0) {
        this.theData.sections.splice(idx, 1)
      }
    },
    removeBarcode (sectionId, barcodeId) {
      const s = this.theData.sections.find(s => s.id === sectionId)
      const idx = s.barcodes.findIndex(b => b.id === barcodeId)
      if (idx >= 0) {
        s.barcodes.splice(idx, 1)
      }
    },
    addBarcodeToSection(sectionId) {
      const s = this.theData.sections.find(s => s.id === sectionId)

      if (s) {
        s.barcodes.push({
          id: Math.round(Math.random() * 10000000),
          codeText: '',
          label: '',
          mode: 'edit',
        })
      }
    }

  },
  mounted() {
    if (this.theData.sections.length === 0) {
      this.addSection()
    }

    setTimeout(() => {
      forEach(this.theData.sections, section => {
        forEach(section.barcodes, barcode => {
          console.log('here')
          this.regenerateBarcode(barcode.id)
        })
      })
    }, 1000)

  }
}
</script>

<style>

</style>
