import BaseModel from '@models/Base'
import UserModel from '@models/User'

export default class ReturnBox extends BaseModel {
  constructor (initialData) {
    super(initialData, {
      columnNames: [
        'id',
        'aasm_state',
        'shipping_method',
        'mail_carrier',
        'part_vendor',
        'created_by_user_id',
        'dropoff_by_technician_id',
        'metadata',
      ],
      dateKeys: [
        'created_at',
        'updated_at',
      ],
      relationshipMap: {
        created_by_user: {
          model: UserModel,
        },
        dropoff_by_technician: {
          model: UserModel,
        },
      },
    })
  }
}