<template>
  <li class="py-2 bg-white space-y-1">
    <div class="flex space-x-3">
      <div class="flex-1 flex justify-between">

        <div class="flex items-center mr-3">
          <p class="text-center text-base mr-4" style="min-width:125px;">{{ timecode }}</p>
          <p class="text-left text-sm mr-4 overflow-hidden overflow-ellipsis flex-nowrap" style="min-width:125px;">{{ technicianName }}</p>
          <div class="flex flex-col">
            <h3 class="text-sm font-medium">{{ body }}</h3>
          </div>
        </div>

        <div class="flex items-center my-0">
          <div class="action-buttons max-width px-4">
            <p v-if="isArchived" class="text-sm font-medium text-gray-600">Archived</p>
            <p v-if="canArchive" class="text-sm font-medium text-indigo-600 hover:text-indigo-500" @click="archive">Archive</p>
          </div>
        </div>
        
      </div>
    </div>
  </li>
</template>

<script>
import { DateTime } from 'luxon'
import OfficeAlert from '@models/OfficeAlert'
import OfficeAlerts from '@http/OfficeAlerts'
import { WindowVariables } from '@utils'

export default {
  name: 'office-alert',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      archiving: false,
    }
  },
  computed: {
    officeAlert () {
      return this.data.isProcessedModel ? this.data : new OfficeAlert(this.data)
    },
    technicianName () {
      return this.officeAlert.technician.fullname
    },
    timecode () {
      const alertFormattedDate = this.officeAlert.created_at.toLocaleString({ weekday: 'short', month: 'short', day: 'numeric' })
      const todayFormatted = DateTime.local().toLocaleString({ weekday: 'short', month: 'short', day: 'numeric' })
      if (alertFormattedDate === todayFormatted) {
        return this.officeAlert.created_at.toLocaleString({ hour: 'numeric', minute: '2-digit' })
      } else {
        return this.officeAlert.created_at.toLocaleString({ month: 'short', day: 'numeric', hour: 'numeric', minute: '2-digit' })
      }
    },
    body () {
      return this.officeAlert.body
    },
    isArchived () {
      return this.officeAlert.archived_at !== null
    },
    canArchive () {
      return !this.isArchived
    },
  },
  methods: {
    archive () {
      this.archiving = true
      OfficeAlerts.archive(this.officeAlert.id)
        .then(() => { location.reload() })
    },
  },
}
</script>

<style>

</style>
