<template>
  <div>
    <GmapMap
      :center="{ lat: 28.5296712, lng: -81.3573469 }"
      :zoom="11"
      map-type-id="terrain"
      style="width: 800px; height: 600px"
    >
      <GmapMarker
        :key="index"
        v-for="(m, index) in markers"
        :position="m"
        :clickable="true"
        :draggable="true"
        @click="center=m"
      />
    </GmapMap>


    <table>
      <thead>
        <tr>
          <th>Customer</th>
          <th>Scheduled Start</th>
          <th>Window Communicated</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(workOrder, idx) in workOrders" :key="idx">
          <td>{{ workOrder.FullName }}</td>
          <td>{{ workOrder.DateScheduledStart }}</td>
          <td>{{ workOrder.communicated_window_start }} to {{ workOrder.communicated_window_end }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import consumer from './channels/consumer'

export default {
  name: 'map-app',
  data () {
    return {
      vehicles: [],
      workOrders: [],

    }
  },
  computed: {
    markers () {
      return this.vehicles.map(v => ({
         lat: v['vehicle_location']['point']['lat'],
         lng: v['vehicle_location']['point']['lon'],
      }))
    }
  },
  created () {
    const that = this

    consumer.subscriptions.create("WebChannel", {
      connected() {
        console.log('WebChannel subscription connected')
        // Called when the subscription is ready for use on the server
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(d) {
        console.log('got some data', d)
        if (d.data.vehicles && d.data.work_orders) {
          console.log('setting data', d.data.vehicles)
          that.vehicles = d.data.vehicles
          that.workOrders = d.data.work_orders
        } else {
          console.log('nope')
        }
        // Called when there's incoming data on the websocket for this channel
      }
    })
  }

}
</script>