import BaseModel from '@models/Base'
import UserModel from '@models/User'

export default class OfficeAlert extends BaseModel {
  constructor (initialData) {
    super(initialData, {
      columnNames: [
        'id',
        'body',
        'technician_id',
        'archived_at',
        'created_at',
        'updated_at',
      ],
      dateKeys: [
        'archived_at',
        'created_at',
        'updated_at',
      ],
      relationshipMap: {
        technician: {
          model: UserModel,
        },
      },
    })
  }
}