import BaseModel from '@models/Base'
import UserModel from '@models/User'
import ReturnBoxModel from '@models/ReturnBox'
import { titleize } from '@utils'

export default class ReturnPart extends BaseModel {
  constructor (initialData) {
    super(initialData, {
      columnNames: [
        'id',
        'part_number',
        'manufacturer',
        'technician_id',
        'created_by_user_id',
        'return_type',
        'return_value',
        'return_core_value',
        'part_vendor',
        'part_vendor_rea_number',
        'work_order_number',
        'purchase_order_number',
        'return_box_id',
        'added_to_return_box_by_user_id',
        'part_reimbursement',
      ],
      dateKeys: [
        'created_at',
        'updated_at',
      ],
      relationshipMap: {
        technician: {
          model: UserModel,
        },
        created_by_user: {
          model: UserModel,
        },
        added_to_return_box_by_user: {
          model: UserModel,
        },
        return_box: {
          model: ReturnBoxModel,
        },
      },
    })
  }

  get key () {
    return `${this.id}-${this.manufacturer}-${this.part_number}`
  }

  get partVendor () {
    return titleize(this.part_vendor)
  }

  get returnType () {
    return titleize(this.return_type)
  }
}