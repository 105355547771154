import axios from 'axios'
import ReturnPart from '@models/ReturnPart'
import { WindowVariables } from '@utils'

const BASE_URL = `${WindowVariables.primaryDomain}/returns/parts`

function init (d) {
  return new ReturnPart(d)
}

export default {
  async addToBox (id, returnBoxId) {
    console.log(WindowVariables.currentUser.id)
    return this.update({
      id,
      return_box_id: returnBoxId,
      added_to_return_box_by_user_id: WindowVariables.currentUser.id,
    })
  },
  async removeFromBox (id) {
    return this.update({
      id,
      return_box_id: null,
      added_to_return_box_by_user_id: null,
    })
  },
  async update (opts) {
    if (!opts.id) {
      return await Promise.reject({ error: 'No record ID specified' })
    } else {
      return await axios.patch(`${BASE_URL}/${opts.id}.json`, {
        return_part: opts,
      }).then(resp => init(resp.data))
    }
  },
  async mark (opts) {
    if (!opts.id) {
      return await Promise.reject({ error: 'No record ID specified' })
    } else if (!opts.state) {
      return await Promise.reject({ error: 'No marked state specified' })
    } else {
      return await axios.patch(`${BASE_URL}/${opts.id}/mark.json`, {
        state: opts.state,
      }).then(resp => init(resp.data))
    }
  }
}